<template>
  <div>
    <v-app-bar dense hide-on-scroll>
      <img :src="require('@/assets/img/logo.png')" height="40" />
      <v-divider class="mx-4" inset vertical></v-divider>
      <img :src="require('@/assets/img/logo3.png')" height="40" />
      <!-- <v-img :src="'/src/img/logo.png'"></v-img> -->
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  data: () => ({}),
  computed: {},
  methods: {},
});
</script>

<style>
.logoimg {
  margin-left: 10em;
}
.profile {
  margin-right: 10em;
}
</style>
