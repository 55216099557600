/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-timedimension/dist/leaflet.timedimension.control.min.css";

Vue.config.productionTip = false;
Vue.prototype.$L = L;

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
