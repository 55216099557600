<template>
  <v-app>
    <Topbar></Topbar>
    <v-main>
      <DashboardView></DashboardView>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";
import DashboardView from "./views/DashboardView.vue";
import Topbar from "./views/topbar.vue";

export default {
  name: "App",

  components: {
    // HelloWorld,
    DashboardView,
    Topbar,
  },

  data: () => ({
    //
  }),
};
</script>
