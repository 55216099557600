<template>
  <v-container>
    <v-row>
      <v-col id="col1" cols="12" sm="12" lg="3">
        <v-subheader>Catchment</v-subheader>
        <v-select
          outlined
          dense
          v-model="catchmentSelected"
          :items="catchmentData"
          item-text="name"
        ></v-select>
        <v-subheader>Hydrological Model</v-subheader>
        <v-select
          outlined
          dense
          v-model="hydrologicalSelected"
          :items="hydrologicalData"
          item-text="name"
        ></v-select>
        <v-subheader>Return periods</v-subheader>
        <v-text-field
          v-model="returnPeriods"
          :rules="rules"
          outlined
          name="name"
        ></v-text-field>

        <v-btn :disabled="!polyPoints" color="green" @click="RunProcessMethod"
          >Find events</v-btn
        >
        <br />
        <v-btn color="blue" :disabled="downloadDisabled" @click="DownloadData"
          >Download events</v-btn
        >
        <br />
        <br />
        <div v-show="showLoadingBar">
          <v-col class="subtitle-1 text-center" cols="12">
            Getting your files
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              color="blue-grey darken-3"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </div>
        <v-alert
          :value="alert"
          color="red"
          dark
          icon="mdi-alert"
          transition="scale-transition"
        >
          Call Failed! You can still download the logs and retry...
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12" lg="9">
        <v-container>
          <v-card-title>Map</v-card-title>
          <div id="map"></div>
          <br />
          <v-textarea
            v-model="logData"
            multi-line
            label="log"
            color="teal"
            readonly
            filled
            outlined
            id="textarea_id"
          >
          </v-textarea>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import L1 from "leaflet-draw";
import axios from "axios";
import "leaflet-draw/dist/leaflet.draw.css";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      alert: false,
      showLoadingBar: false,
      catchmentSelected: undefined,
      hydrologicalSelected: undefined,
      returnPeriods: "0.5,1,2,5,10,50,100,1000,10000",
      downloadDisabled: true,
      polyline: undefined,
      hasToolbox: true,
      logData: "",
      map: undefined,
      rules: [
        (v) => !!v || "required",
        (v) =>
          /^(?!0$)\d+(?:[,.][05])?(,[0-9]{1,3})?(,([1-9][0-9]{0,4}))*$/.test(
            v
          ) || "The return periods must be between 0.5 and 10000",
      ],
      apiURL: "https://ses.imdcapps.be/api/",
      pointData: "",
      ijzerData: "",
      hydrologicalData: [],
      catchmentData: [],
      polyPoints: undefined,
      eventID: undefined,
      customLayerGroup: L.layerGroup(),
      // layerGroupIjzer: L.customLayerGroup(),
      options: {
        drawControl: true,
      },
      markerIcon: L.divIcon({
        className: "icon mdi mdi-map-marker",
        iconSize: [98, 95],
        shadowSize: [12, 12],
        iconAnchor: [6, 6],
        shadowAnchor: [6, 6],
        popupAnchor: [0, -5],
      }),
    };
  },
  methods: {
    DownloadData() {
      axios({
        url: this.apiURL + "events/export?task_id=" + this.eventID,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.eventID + ".zip");
        document.body.appendChild(link);
        link.click();
      });
    },
    async RunProcessMethod() {
      this.downloadDisabled = true;
      let xCo = this.polyPoints[0].map((e) => e.lng);
      let yCo = this.polyPoints[0].map((e) => e.lat);
      // let textarea = document.getElementById('textarea_id');
      let body = {
        Catchment: this.catchmentSelected,
        HydrologicalModel: this.hydrologicalSelected,
        area: {
          X: xCo,
          Y: yCo,
        },
        returnperiods: this.convertedReturnPeriods,
      };
      this.logData =
        moment().format("DD-MM-YYYY HH:mm:ss") +
        " | " +
        "Selected: " +
        this.catchmentSelected +
        "\n" +
        moment().format("DD-MM-YYYY HH:mm:ss") +
        " | " +
        "Selected: " +
        this.hydrologicalSelected +
        "\n";
      let data = await axios.post(
        this.apiURL + "events/find_maatgevende_events/",
        body
      );
      this.logData =
        moment().format("DD-MM-YYYY HH:mm:ss") +
        " | " +
        data.statusText +
        "\n" +
        moment().format("DD-MM-YYYY HH:mm:ss") +
        " | " +
        data.data.info +
        "\n" +
        moment().format("DD-MM-YYYY HH:mm:ss") +
        " | " +
        "task ID: " +
        data.data.task_id +
        "\n";
      this.eventID = data.data.task_id;
      this.pollingStatusCall(data.data.task_id) + this.logData;
    },

    async pollingStatusCall(id) {
      let data;
      try {
        data = await axios.get(this.apiURL + "events/getstatus?task_id=" + id);
      } catch (error) {
        this.alert = true;
        this.downloadDisabled = false;
        this.showLoadingBar = false;
        this.logData =
          moment().format("DD-MM-YYYY HH:mm:ss") + " | " + "ERROR" + "\n";
        this.logData = error + "\n" + this.logData;
        this.logData =
          "You can still download the logs and retry..." + "\n" + this.logData;
        setTimeout(() => {
          this.alert = false;
        }, 7000);
      }

      if (data.data.status == "PENDING") {
        this.showLoadingBar = true;
        this.logData =
          moment().format("DD-MM-YYYY HH:mm:ss") +
          " | " +
          data.data.status +
          "\n" +
          this.logData;
        setTimeout(() => {
          this.pollingStatusCall(id);
        }, 8000);
      }
      if (data.data.status == "SUCCESS") {
        this.showLoadingBar = false;
        this.downloadDisabled = false;
        this.logData =
          moment().format("DD-MM-YYYY HH:mm:ss") +
          " | " +
          "Maatgevende Events: " +
          data.data.result["Maatgevende events"] +
          "\n" +
          this.logData;
      }
    },

    setupLeafletMap() {
      if (!this.map) {
        this.map = L.map("map").setView([50.5039, 4.4699], 8);
        let drawnItems = new L.FeatureGroup();
        this.map.addLayer(drawnItems);
        let MB_ATTR = `Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors,
      <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
      Imagery &copy <a href="http://mapbox.com">Mapbox</a>`;

        let MB_URL =
          "https://api.mapbox.com/styles/v1/kobededecker/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
        let cartodbAttribution =
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

        L.tileLayer(MB_URL, {
          attribution: MB_ATTR,
          // id: "mapbox/streets-v11",
          // tileSize: 256,
          id: "ckg9boeuq7czw19olcl8itxa3",
          zIndex: 0,
          // legend_name: "Vector",
          maxZoom: 24,
          access_token:
            "pk.eyJ1Ijoia29iZWRlZGVja2VyIiwiYSI6ImNqNDhobDJ4OTBlZWszMnF6Y3Z1eTYzanMifQ.YXCOQ6FhMbp89nKSZXwFSw",
        }).addTo(this.map);
        let drawControl = new L.Control.Draw({
          draw: {
            position: "topRight",
            // polygon: false,
            polyline: false,
            // rectangle: false,
            circlemarker: false,
            polygon: {
              title: "Draw a simple polygon!",
              allowIntersection: false,
              drawError: {
                color: "#b00b00",
                timeout: 1000,
              },
              shapeOptions: {
                color: "#bada55",
              },
              showArea: true,
            },
            circle: false,
            marker: false,
          },
          edit: {
            featureGroup: drawnItems,
          },
        });
        this.map.addControl(drawControl);

        this.map.on("draw:created", (e) => {
          let layer = e.layer;
          this.polyPoints = layer._latlngs;
          this.polyline = layer.toGeoJSON();

          drawnItems.addLayer(layer);
        });
      }
    },

    async markersCall() {
      let data = await axios.get(
        this.apiURL +
          "events/getcoordinates?Catchment=" +
          this.catchmentSelected
      );
      this.pointData = JSON.parse(data.data);

      this.plotMarkers(this.pointData);
    },

    catchmentCall() {
      axios.get(this.apiURL + "catchments/").then((response) => {
        this.catchmentData = response.data;
        if (!this.catchmentSelected) {
          this.catchmentSelected = this.catchmentData[0].name;
        }
      });
    },
    hydrologicalCall() {
      axios.get(this.apiURL + "models/").then((response) => {
        this.hydrologicalData = response.data;
        this.hydrologicalSelected = this.hydrologicalData[0].name;
      });
    },
    plotMarkers(data) {
      this.map.removeLayer(this.customLayerGroup);
      let plotLayer = L.layerGroup();
      Object.keys(data.X).forEach((e) => {
        let marker = L.marker([data.Y[e], data.X[e]], {
          icon: this.markerIcon,
        })
          .bindPopup("<b>" + data.River[e] + "</b>")
          .on("click", this.onClick);
        plotLayer.addLayer(marker);
      });
      plotLayer.addTo(this.map);
      this.customLayerGroup = plotLayer;
    },
  },
  computed: {
    convertedReturnPeriods() {
      let i = this.returnPeriods
        .split(",")
        .map(Number)
        .sort((a, b) => a - b);
      return i;
    },
  },
  watch: {
    catchmentSelected: function (val) {
      this.markersCall();
      this.catchmentSelected = val;
    },
  },
  mounted() {
    this.catchmentCall();
    this.hydrologicalCall();
    this.markersCall();
    this.setupLeafletMap();
  },
});
</script>

<style>
#col1 {
  margin-top: 55px;
}

#map {
  z-index: 1;
  height: 50em;
}
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.v-btn {
  margin-top: 10px;
}
</style>
